<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card card-shadow">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xs-12 col-md-4 text-left">
                <div class="card-body">
                  <h5 class="card-title">{{ recipe.name }}</h5>
                  <p class="card-text">
                    {{ recipe.description }}
                    <span v-if="recipe.url" class="mt-3">
                      Recipe found at
                      <b-link :href="recipe.url">{{ recipe.url }}</b-link>
                    </span>
                  </p>
                </div>
              </div>
              <div class="col-xs-12 col-md-4 text-center">
                <div class="card-body vertical-middle">
                  <b-button variant="link" v-b-modal.modal_recipe_reviews>
                    Reviews:
                    <span class="badge badge-primary">{{
                      reviews.length
                    }}</span>
                  </b-button>

                  <b-modal
                    id="modal_recipe_reviews"
                    title="Reviews"
                    header-bg-variant="primary"
                    header-text-variant="light"
                    ok-title="Close"
                    ok-variant="primary"
                    ok-only
                  >
                    <div id="review-container" class="container">
                      <div
                        id="review-edit-card"
                        class="card"
                        v-if="isAuthenticated"
                      >
                        <div class="card-body">
                          <div class="row">
                            <div class="col-3 pr-0">
                              <img
                                :src="userAvatar"
                                class="img img-rounded recipe-review-avatar"
                              />
                            </div>
                            <div class="col-9">
                              <p>
                                <strong>{{ userAuthorName }}</strong>
                                <b-form-rating
                                  v-model="review.rating"
                                  variant="warning"
                                  no-border
                                  inline
                                  class="float-right"
                                ></b-form-rating>
                              </p>
                              <div class="clearfix"></div>
                              <p>
                                <textarea
                                  class="form-control recipe-review-textarea"
                                  type="text"
                                  placeholder="e.g. I loved this recipe..."
                                  rows="6"
                                  v-model="review.review"
                                ></textarea>
                              </p>
                              <b-button
                                variant="outline-primary"
                                class="float-right"
                                @click="postReview"
                                v-if="!reviewKey"
                              >
                                Post
                              </b-button>
                              <b-button
                                variant="outline-danger"
                                size="sm"
                                class="float-right ml-1"
                                @click="deleteReview"
                                v-if="reviewKey"
                              >
                                <i class="far fa-trash-alt"></i> Delete
                              </b-button>
                              <b-button
                                variant="outline-primary"
                                size="sm"
                                class="float-right"
                                @click="editReview"
                                v-if="reviewKey"
                              >
                                <i class="fas fa-edit"></i> Edit
                              </b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr v-if="isAuthenticated" />
                      <div
                        class="card"
                        v-for="(review, index) in recipeReviews"
                        :key="index"
                      >
                        <div class="card-body">
                          <div class="row">
                            <div class="col-3 pr-0">
                              <img
                                :src="
                                  review.avatar
                                    ? review.avatar
                                    : avatarPlaceholder
                                "
                                class="img img-rounded recipe-review-avatar"
                              />
                            </div>
                            <div class="col-9">
                              <p>
                                <strong>{{
                                  review.author ? review.author : "Anonymous"
                                }}</strong>
                                <b-form-rating
                                  v-model="review.rating"
                                  variant="warning"
                                  no-border
                                  inline
                                  readonly
                                  class="float-right"
                                ></b-form-rating>
                              </p>
                              <div class="clearfix"></div>
                              <p>{{ review.review }}</p>
                              <!--<p class="text-secondary text-right">15 Minutes Ago</p>-->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-modal>
                  <div>
                    <b-form-rating
                      :value="recipeRating"
                      variant="warning"
                      no-border
                      inline
                      readonly
                    ></b-form-rating>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-md-4 text-right">
                <div class="card-body">
                  <span class="emphasis">Author</span> {{ recipe.author }}
                </div>
              </div>
            </div>
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <div class="container-fluid">
                <div class="row">
                  <div class="col">
                    <b-carousel
                      id="recipe-carousel"
                      :interval="4000"
                      controls
                      indicators
                      background="#424242"
                      img-height="300"
                      style="text-shadow: 1px 1px 2px #333;"
                    >
                      <b-carousel-slide
                        :img-src="image"
                        v-for="image in images"
                        :key="image"
                      ></b-carousel-slide>
                    </b-carousel>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="container">
                      <div class="row">
                        <div class="col text-center" v-if="recipe.servings">
                          <span class="emphasis">Servings</span>
                          {{ recipe.servings }}
                        </div>
                        <div class="col text-center" v-if="recipe.prep_time">
                          <span class="emphasis">Prep</span>
                          {{ recipe.prep_time }} minutes
                        </div>
                        <div class="col text-center" v-if="recipe.cook_time">
                          <span class="emphasis">Cook</span>
                          {{ recipe.cook_time }} minutes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-xs-0 col-md-2 text-left"></div>
                  <div class="col-xs-12 col-md-8 text-center">
                    <div
                      class="addthis_inline_share_toolbox"
                      :data-url="'recipes/' + recipe.slug"
                      v-if="recipe.public"
                    ></div>
                    <i v-if="!recipe.public"
                      >This recipe is private and cannot be shared on social
                      media.</i
                    >
                  </div>
                  <div class="col-xs-12 col-md-2 text-right">
                    <b-link
                      :to="{
                        name: 'recipe-edit',
                        params: { id: refkey, tab: 'sharing' }
                      }"
                      class="btn btn-outline-primary"
                      v-if="canEdit"
                      ><i class="fas fa-share-alt"></i> Sharing</b-link
                    >
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="container-fluid">
                <div class="row">
                  <div id="ingredients" class="col-xs-12 col-md-3 text-center">
                    <div class="form-group">
                      <b>Format:</b>
                      <b-form-checkbox
                        v-model="format"
                        switch
                        inline
                        class="ml-2"
                      >
                        {{ numberFormat }}
                      </b-form-checkbox>
                    </div>
                    <table class="table table-striped text-left">
                      <thead>
                        <tr>
                          <th scope="col">Ingredient</th>
                          <th scope="col">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(ingredient, index) in ingredients"
                          :key="index"
                        >
                          <td>{{ ingredient.food.name }}</td>
                          <td class="ingredient-amount">
                            {{ ingredient.amount }}
                            {{
                              ingredient.unit.label
                                ? ingredient.unit.label
                                : ingredient.unit.name
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div id="instructions" class="col-xs-12 col-md-9">
                    <ul class="list-group">
                      <li
                        class="list-group-item"
                        v-for="instruction in recipe.instructions"
                        :key="instruction.numeral"
                      >
                        <div class="d-flex w-100 justify-content-between">
                          <h5 class="mb-1">Step {{ instruction.numeral }}</h5>
                        </div>
                        <p class="mb-1">{{ instruction.text }}</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xs-12 col-md-3"></div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="container-fluid">
                <div class="row">
                  <div class="col text-left">
                    <b-link :to="{ name: 'recipes' }" class="btn btn-link"
                      ><i class="far fa-arrow-alt-circle-left"></i> Back</b-link
                    >
                    <b-link
                      :to="{ name: 'recipe-edit', params: { id: refkey } }"
                      class="btn btn-link"
                      v-if="canEdit"
                      ><i class="fas fa-edit"></i> Edit</b-link
                    >
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import Recipe from "../../firebase/recipes";
import Fraction from "fraction.js";

export default {
  name: "RecipeView",
  mixins: [FirebaseMixin, UtilsMixin],
  components: {},
  data() {
    return {
      ref: "recipes",
      bind: [],
      refkey: null,
      recipe: {
        name: "",
        description: "",
        url: "",
        servings: "",
        prep_time: "",
        cook_time: "",
        public: true,
        rating: null,
        tags: [],
        images: [],
        ingredients: [],
        instructions: []
      },
      reviews: [],
      reviewKey: null,
      review: {
        author: "",
        rating: 3,
        review: ""
      },
      images: [],
      ingredients: [],
      format: false,
      avatarPlaceholder: require("../../assets/img_avatar.png"),
      profile: {}
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    ...mapGetters(["userAvatar", "userAuthorName", "userNumberFormat"]),
    userNumFormat() {
      return this.userNumberFormat;
    },
    numberFormat() {
      return this.format ? "Fractions" : "Decimals";
    },
    canEdit() {
      return true;
    },
    sharedWithMe() {
      return false;
    },
    recipeRating() {
      let ratings = [];
      this.reviews.forEach(review => {
        ratings.push(review.rating);
      });
      return ratings.length > 0
        ? ratings.reduce((a, b) => a + b) / ratings.length
        : null;
    },
    recipeReviews() {
      const _this = this;
      let reviews = [...this.reviews];
      reviews.forEach((review, index) => {
        if (review.user_uid === _this.userProfile.uid) {
          reviews.splice(index, 1);
        }
      });
      return reviews;
    }
  },
  watch: {
    userNumFormat: {
      immediate: true,
      handler(format) {
        this.format = format === "fractions";
      }
    },
    refkey: {
      immediate: false,
      handler(key) {
        this.bindObject(this.ref, key, "recipe");
        this.bindObject("reviews", key, "reviews");
      }
    },
    recipe: {
      immediate: true,
      handler(recipe) {
        const _this = this;
        this.images = [];
        recipe.images = Array.isArray(recipe.images) ? recipe.images : [];
        recipe.images.forEach(image => {
          _this.getImageUrl("recipes/" + image).then(url => {
            _this.images.push(url);
          });
        });
        recipe.ingredients = Array.isArray(recipe.ingredients)
          ? recipe.ingredients
          : [];
        this.changeNumberFormat();
        recipe.instructions = Array.isArray(recipe.instructions)
          ? recipe.instructions
          : [];
      }
    },
    format: {
      immediate: true,
      handler() {
        this.changeNumberFormat();
      }
    },
    reviews: {
      immediate: false,
      handler(reviews) {
        const _this = this;
        reviews.forEach(review => {
          if (review.user_uid === _this.userProfile.uid) {
            _this.reviewKey = review[".key"];
            _this.review = { ...review };
          }
        });
      }
    }
  },
  methods: {
    exit() {
      this.navigate("recipes");
    },
    changeNumberFormat() {
      const _this = this;
      console.log("Number Format: " + this.numberFormat);
      this.ingredients = [];
      if (this.format) {
        // Fractions
        this.recipe.ingredients.forEach(ingredient => {
          const newIng = { ...ingredient };
          const f = new Fraction(newIng.amount);
          newIng.amount = f.toFraction(true);
          _this.ingredients.push(newIng);
        });
      } else {
        // Decimals
        this.recipe.ingredients.forEach(ingredient => {
          const newIng = { ...ingredient };
          const f = new Fraction(newIng.amount);
          newIng.amount = f.valueOf();
          _this.ingredients.push(newIng);
        });
      }
    },
    save() {
      const recipe = { ...this.recipe };

      return this.updateObject(this.ref, this.refkey, recipe)
        .then(() => {
          console.log("Recipe saved successfully");
        })
        .catch(error => {
          console.log(error);
          window.toastr.error(
            "An error occurred while saving the recipe. Please try again later."
          );
        });
    },
    postReview() {
      const _this = this;
      let review = { ...this.review };
      review.author = this.userAuthorName;
      review.user_uid = this.userProfile.uid;
      this.createObject("reviews/" + this.refkey, review)
        .then(result => {
          _this.reviewKey = result.key;
          window.toastr.success("Review posted successfully");
        })
        .catch(error => {
          console.log(error);
          window.toastr.error(
            "An error occurred while posting the review. Please try again later."
          );
        });
    },
    editReview() {
      let review = { ...this.review };
      review.author = this.userAuthorName;
      review.user_uid = this.userProfile.uid;
      this.updateObject("reviews/" + this.refkey, this.reviewKey, review)
        .then(() => {
          window.toastr.success("Review updated successfully");
        })
        .catch(error => {
          console.log(error);
          window.toastr.error(
            "An error occurred while updating the review. Please try again later."
          );
        });
    },
    deleteReview() {
      const _this = this;
      this.deleteObject("reviews/" + this.refkey, this.reviewKey)
        .then(() => {
          _this.review = {
            rating: 3,
            review: ""
          };
          _this.reviewKey = null;
          window.toastr.success("Review deleted successfully");
        })
        .catch(error => {
          console.log(error);
          window.toastr.error(
            "An error occurred while deleting the review. Please try again later."
          );
        });
    },
    removeShare() {
      const r = new Recipe();
      r.remove(this.refkey, this.userProfile.family_key)
        .then(() => {
          this.navigate("recipes");
        })
        .catch(error => {
          console.log("removeShare", error);
          window.toastr.error(
            "An error occurred while removing the share. Please try again later."
          );
        });
    }
  },
  created() {
    this.getIdFromSlug(this.$route.params.slug).then(id => {
      this.refkey = id;
    });
  }
};
</script>

<style>
.card-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -4px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.recipe-card-body-text {
  height: 130px;
  overflow: hidden;
  margin-bottom: 10px;
}

.recipe-row {
  padding-top: 4px;
  padding-left: 9px;
  padding-right: 9px;
}

.recipe-card {
  margin: 0;
  padding: 5px;
  min-width: 20%;
}

.card-img-top {
  height: 150px;
  object-fit: cover;
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-link {
  color: rgb(255, 152, 0);
  font-weight: 500;
}

#recipe-tags {
  margin-left: 0px;
}

.recipe-tag {
  margin-left: 5px;
}

.chip {
  display: inline-block;
  padding: 0 15px;
  height: 30px;
  font-size: 14px;
  line-height: 30px;
  border-radius: 15px;
  background-color: #424242;
}

.chip img {
  float: left;
  margin: 0 8px 0 -15px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}

.chip-sm {
  display: inline-block;
  padding: 0;
  height: 30px;
  font-size: 14px;
  line-height: 30px;
  border-radius: 15px;
}

.chip-sm img {
  float: left;
  margin: 0 8px 0 -15px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}

.gold-star {
  color: gold;
  font-size: 1.2em;
}

.b-rating {
  padding: 0;
  height: 24px;
}

#recipe-carousel {
  height: 300px;
  max-width: 600px;
  background-color: #424242;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
}

#recipe-carousel .carousel-inner {
  height: inherit;
  max-width: inherit;
}

.carousel-item > img {
  object-fit: contain;
  object-position: 50% 50%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.form-group {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.form-inline .form-group {
  margin-left: 0;
}

#recipe-edit-tags .custom-select {
  width: auto;
}

.recipe-handle {
  width: 30px;
  height: 28px;
  float: left;
  cursor: pointer;
}

.ordered-list-group {
  list-style: decimal inside;
}

.ordered-list-group-item {
  display: list-item;
}

#ingredients-container {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

#recipe-ingredients-list .btn-outline-secondary {
  color: #495057;
  background-color: white;
  background-image: none;
  border-color: #ced4da;
}

#ingredients th {
  border-top: 0;
}

.ingredients-amount-col {
  min-width: 285px;
}

.recipe-ingredients-amount {
  max-width: 100px;
  float: left;
}

.recipe-ingredients-unit {
  max-width: 185px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

#instructions-container {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

#instructions .list-group-item {
  border: 0;
}

#recipe-instructions-list {
  list-style-type: none;
}

#recipe-instructions-list > li {
  min-height: 108px;
}

#recipe-instructions-list .row {
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

#recipe-instructions-list .row:first-of-type {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  margin-top: 22px;
}

#recipe-instructions-list .row:last-of-type {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  margin-bottom: 22px;
}

.col-ingredient-handle {
  width: 30px;
  margin-right: 5px;
  padding-top: 7px;
}

.col-ingredient-desc {
  width: calc(100% - 35px);
}

.col-ingredient-delete {
  width: 72px;
  margin-left: 5px;
}

.recipe-instructions-numeral {
  float: left;
  height: 38px;
  padding-top: 8px;
}

.recipe-instructions-textarea {
  height: 100px;
  width: 100%;
}

#images-container {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.fileinput-button {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.fileinput-button input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  font-size: 200px !important;
  direction: ltr;
  cursor: pointer;
}

.explain-text {
  font-style: italic;
  margin-top: 5px;
  margin-left: 10px;
}

.recipe-sharing-row {
  margin-top: 15px;
}

#sharing-family-rows {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.recipe-review-avatar {
  width: 100%;
  max-width: 70px;
  height: 70px;
}

.emphasis {
  font-weight: 500 !important;
}
</style>
